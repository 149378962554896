/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useStyles } from './styles';

const Imprint = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        imprint {
          items
          title
        }
      }
    }
  `);
  const { title, items } = dataJson.imprint;

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      {items.map((block, blockIndex) => (
        <div key={blockIndex} className={classes.imprintBlock}>
          {block.map((item) => (
            <p key={item} className={`${classes.imprint} ${classes.text}`}>
              {item}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Imprint;
