import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { grey, white, blood } = colors;

export const useStyles = createUseStyles({
  container: {
    flex: [1, 1, 'auto'],
    background: white,
    margin: [56, 'auto', 'auto', '40%'],
    padding: [20, 41, 41],
    zIndex: 100,
  },
  title: {
    fontFamily: 'FedraSerifAStd-Demi',
    fontSize: 39,
    color: grey[60],
    lineHeight: 1.21,
  },
  textFirst: {
    marginTop: 0,
  },
  imprint: {
    margin: 0,
  },
  imprintBlock: {
    marginBottom: 10,
  },
  text: {},
  policyTitle: {
    marginBottom: 0,
  },
  '@media (max-width: 1600px)': {
    title: {
      fontSize: 32,
    },
    text: {
      fontSize: 18,
    },
  },
  '@media (max-width: 1400px)': {
    title: {
      fontSize: 28,
    },
    text: {
      fontSize: 16,
    },
  },
  '@media (max-width: 1280px)': {
    title: {
      fontSize: 22,
    },
    text: {
      fontSize: 14,
    },
  },
  '@media (max-width: 800px)': {
    container: {
      background: blood,
      margin: 0,
      padding: [10, 36, 36],
    },
    title: {
      fontSize: 18,
      lineHeight: 1.22,
      color: white,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.5,
      color: white,
    },
  },
});
